<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/countryside-banner.jpg">
			<div class="words">
				<p>
					<span>环保-等离子空气消毒器</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>环保</span>
					<p class="crumbs"><a>您的位置：</a> <a>产品</a>> <a>等离子空气消毒器</a> </p>
				</div>

 

				<div class="content">
					<h3>产品介绍</h3>
					<p>
						等离子体是物质在高温或特定激励(例如高压电离)下的一种物质存在聚集状态。是除通常物质三态:固态、液态、气态以外物质的第四种存在状态。
					</p>
					<p>
						等离子体是由大量正负带电粒子和中性粒子组成的、并表现出集体电场作用的、电荷整体呈准中性的气体云。由针状电正极和板式不锈钢阴极组成的等离子体反应器,加上高频高压脉冲电压,对空气进行高压电离,空气中的物质在针尖电极周围产生大范围的稳定放电等离子体。
					</p>
					<p>
						在外加高压电场的作用下,逸出电子和自然电子被加速,获得很高能量。一方面其内部的电场作用,对细菌细胞膜构成击穿和破坏;另一方面打开气体分子键,生成单原子分子、负氧离子、OH离子和自由氧原子、H2O等自由基,具有活化和氧化能力。利用此原理在针状电极上加上高电压发生非热均匀性放电,产生稳定放电的等离子体进行灭杀细菌、病毒,分解有害有机物质。
					</p>

					<img src="@/assets/images/products/Sterilizer/01.jpg" alt="">
					<br />
					<h3><b>产品1：</b></h3>
					<img src="@/assets/images/products/Sterilizer/02.jpg" alt=""><br />
					<h3><b>产品2：</b></h3>
					<img src="@/assets/images/products/Sterilizer/03.jpg" alt=""><br />
					<h3><b>产品3：</b></h3>
					<img src="@/assets/images/products/Sterilizer/04.jpg" alt=""><br />
					<h3><b>产品4：</b></h3>
					<img src="@/assets/images/products/Sterilizer/05.jpg" alt=""><br />
					<h3><b>产品5：</b></h3>
					<img src="@/assets/images/products/Sterilizer/06.jpg" alt=""><br />
					<h3><b>产品6：</b></h3>
					<img src="@/assets/images/products/Sterilizer/07.jpg" alt=""><br />
					<h3><b>产品7：</b></h3>
					<img src="@/assets/images/products/Sterilizer/08.jpg" alt=""><br />
					<h3><b>产品8：</b></h3>
					<img src="@/assets/images/products/Sterilizer/09.jpg" alt="">
					<table class="parameter_table">
						<caption><b>技术参数</b></caption>
						<tr>
							<td>产品型号:FJ-D80P </td>
							<td>产品型号:FJ-D11OP</td>
						</tr>
						<tr>
							<td>产品型号: FJ-D150P </td>
							<td>额定电压:220V/50Hz</td>
						</tr>
						<tr>
							<td>额定电压:220V/50Hz</td>
							<td>控制方式:空调联动或手动</td>
						</tr>
						<tr>
							<td>控制方式:空调联动或手动</td>
							<td>控制方式:空调联动或手动</td>
						</tr>
						<tr>
							<td>消毒因子:等离子体</td>
							<td>消毒因子:等离子体</td>
						</tr>
						<tr>
							<td>消毒因子:等离子体 </td>
							<td> 功率:5W</td>
						</tr>
						<tr>
							<td>功率:7W </td>
							<td>功率:9W</td>
						</tr>
						<tr>
							<td>适用体积:80立方 </td>
							<td> 适用体积:110立方</td>
						</tr>
						<tr>
							<td>适用体积:150立方</td>
							<td>产品尺寸:580×280×100mm</td>
						</tr>
						<tr>
							<td>产品尺寸:780×280×100mm </td>
							<td>产品尺寸:980×280×100mm</td>
						</tr>
						<tr>
							<td>回风口开孔尺寸:600×300mm </td>
							<td>回风口开孔尺寸:800×300mm</td>
						</tr>
						<tr>
							<td>回风口开孔尺寸:1000×300mm</td>
							<td>外壳材质:钣金喷塑</td>
						</tr>
						<tr>
							<td>外壳材质:钣金喷塑 </td>
							<td>外壳材质:钣金喷塑</td>
						</tr>

					</table>

					<h3>产品9：</h3>
					<img src="@/assets/images/products/Sterilizer/10.jpg" alt="">
					<h3>产品10：</h3>
					<img src="@/assets/images/products/Sterilizer/11.jpg" alt="">
					<h3>产品11：</h3>
					<img src="@/assets/images/products/Sterilizer/12.jpg" alt="">
					<h3>产品证书：</h3>
					<img src="@/assets/images/products/Sterilizer/13.jpg" alt="">
					<img src="@/assets/images/products/Sterilizer/14.jpg" alt="">
				</div>


				<div class="productsChilder">
					<h3 class="productsChilder-title">浙江飞骏医疗科技有限公司简介</h3>
					<div class="content">
						<p>
							浙江飞骏医疗科技有限公司是一家致力于空气消毒行业集产品研发、设计、生产、销售、技术服务于一体的股份制有限公司企业。
						</p>
						<p>
							公司技术力量雄厚,生产和检测设备齐全,公司的主要产品包括风机盘管式空气消毒器,管道式空气消毒器、移动式空气消毒器、壁挂式空气消毒器、吸顶式空气消毒器、床单位臭氧消毒器、紫外线灯管、过滤网等。
						</p>
						<p>
							公司与国家级设计院和高等院校紧密合作,不断进行技术革新,完善产品性能。本公司专注细分领域,深耕产品创新,秉承“技术创新即产品的活力,质量服务即企业的生命”企业宗旨,坚持“质量第一、诚信为本、客户满意、持续改进"质量理念,专注于消毒技术的研究和开发。
						</p>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"
export default ({
	components: {
		MenuLayout,
	},
	data() {
		return {
			currentComponts: 'OilFumeSeparation'
		}
	},
	methods: {

	}
});
</script>
